<template lang="pug">
include ../../../configs/template
div.row.mt-3.text-left
  div.col-12
    +data-info('nameUK', 'fulNameUkrainian')
  div(v-if="sailorDocument.last_name_eng").col-12
    +data-info('nameEN', 'fulNameEnglish')
  div.col-12
    +data-info('civilPassport.type', 'passportTypeByID(sailorDocument.passport_type)[nameLang]')
  div.col-12
    +data-info('citizenship', 'getDirectoryObject({ id: sailorDocument.country, value: "country"})?.[valueLang]')
  div(v-if="sailorDocument.sex").col-12
    +data-info('sex', 'getDirectoryObject({ id: sailorDocument.sex, value: "sex" }).value_ukr')
  div.col-12
    +data-info('serialAndNum', 'sailorDocument.serial')
  div.col-12(v-if="sailorDocument.inn && !sailorDocument.tax_number")
    +data-info('taxNumber', 'sailorDocument.inn')
  div.col-12(v-if="sailorDocument.tax_number")
    +data-info('taxNumber', 'sailorDocument.tax_number')
  div.col-12
    +data-info('dateIssue', 'getDateFormat(sailorDocument.date_start)')
  div(v-if="sailorDocument.date_end").col-12
    +data-info('dateTermination', 'getDateFormat(sailorDocument.date_end)')
  div.col-12
    +data-info('passportIssued', 'sailorDocument.issued_by')
  div(v-if="registrationAddress").col-12
    +data-info('registrationDoc', 'registrationAddress')
  div(v-if="residentAddress").col-12
    +data-info('residentPlace', 'residentAddress')
  div.col-12
    +data-info('status', 'statusDocumentName')(:class="getStatus(sailorDocument.status_document)")
  div(v-if="sailorDocument.status_document === STATUSES_DOCUMENT_CONSTANTS.SAILOR.PASSPORTS.CITIZEN.REJECTED").col-12.text-left.d-flex
    div(v-if="sailorDocument.comments?.length").col-sm-12.p-0
      h5.p-0.m-0 {{$t('rejectionReasonStatement')}}:
      v-divider
      div(v-for="(comment, index) in sailorDocument.comments" :key="comment.id").ml-4
        div(v-if="comment.additional_info.reason")
          +data-info('rejectionReasonStatement','getDirectoryObject({ id: comment.additional_info.reason, value: "rejectionReasons" })?.[nameLang]')
        div(v-if="checkAccess('backOffice')").pt-2.py-3
          +data-info('createdBy', 'comment.created_by.name')
        div.pt-2.py-3
          +data-info('dateCreate', 'new Date(comment.created_by.date).toLocaleString()')
        div.pt-2.py-3
          +data-info('reasonComment', 'comment.comment')
        v-divider(v-if="index !== sailorDocument.comments.length-1")
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { getDateFormat, getDirectoryObject, getStatus } from '@/mixins/main'
import { STATUSES_DOCUMENT_CONSTANTS } from '@/configs/constants'
import { checkAccess } from '@/mixins/permissions'

export default {
  name: 'SailorCitizenPassportInfo',
  props: {
    sailorDocument: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      checkAccess,
      STATUSES_DOCUMENT_CONSTANTS,
      getDateFormat,
      getStatus
    }
  },
  computed: {
    ...mapGetters(['passportTypeByID', 'rejectionReasonByID', 'getDirectoryObject']),
    ...mapState({
      valueLang: state => (state.main.lang === 'en') ? 'value_eng' : 'value',
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr'
    }),
    statusDocumentName () {
      return getDirectoryObject({ value: 'statuses', id: this.sailorDocument.status_document })[this.nameLang]
    },
    fulNameUkrainian () {
      return `${this.sailorDocument.last_name_ukr} ${this.sailorDocument.first_name_ukr} ${this.sailorDocument.middle_name_ukr}`
    },
    fulNameEnglish () {
      return `${this.sailorDocument.last_name_eng} ${this.sailorDocument.first_name_eng}`
    },
    registrationAddress () {
      if (this.sailorDocument?.registration_address) {
        return `${this.sailorDocument.registration_address.index || ''} ${this.sailorDocument.registration_address.city.country?.[this.valueLang] || ''},
         ${this.sailorDocument.registration_address.city.region?.[this.valueLang] || ''}, ${this.sailorDocument.registration_address.city.city?.[this.valueLang] || ''},
         ${this.sailorDocument.registration_address.street || ''}, ${this.sailorDocument.registration_address.house || ''}, ${this.sailorDocument.registration_address.flat || ''}`
      } else return ''
    },
    residentAddress () {
      if (this.sailorDocument?.residence_address) {
        return `${this.sailorDocument.residence_address.index || ''} ${this.sailorDocument.residence_address.city.country?.[this.valueLang] || ''},
         ${this.sailorDocument.residence_address.city.region?.[this.valueLang] || ''}, ${this.sailorDocument.residence_address.city.city?.[this.valueLang] || ''},
         ${this.sailorDocument.residence_address.street || ''}, ${this.sailorDocument.residence_address.house || ''}, ${this.sailorDocument.residence_address.flat || ''}`
      } else return ''
    }
  }
}
</script>
